.App {
  display: flex;
  align-items: stretch;
}

td {
  border: 1px solid;
  padding: 0.5em;
}

td.noCell {
  border: none;
}

table {
  border-collapse: collapse;
}

th {
  padding-inline: 0.3em;
}

td.yearCell {
  padding-block: 0;
  padding-inline: 0.3em;
  border: 0;
}

td.formCell {
  padding-block: 0;
  padding-inline: 0;
  border: 0;
}

td > input {
  display: block;
}

div.childRow {
  display: flex;
}

div.childRow label.input-flex {
  flex-grow: 1;
}

div.Simulation {
  padding: 2em;
  flex-grow: 1;
  flex-basis: 50vw;
}

.SimulationInput {
  background-color: #eee;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
}

fieldset {
  background-color: #ddd;
}
fieldset legend {
  font-weight: bold;
  float: left;
  /*border: 1px solid blue;*/
}
fieldset > div {
  margin-top: 2em;
  /*border: 1px solid red;*/
}

label.input-flex {
  display: flex;
  align-items: baseline;
  gap: 1em;
}

span.input-wrapper {
  flex-grow: 1;
  width: 5em;
}

div.field-error {
  font-size: 14px;
  color: #ff4d4f;
}

@media (width < 60em) {
  .App {
    flex-direction: column;
  }
}

@media (60em <= width) {
  .App {
    height: 100vh;
  }
  .SimulationInputScrollable,
  .Simulation {
    overflow-y: auto;
  }
}
